import react, { useEffect, useState } from 'react';
import './Footer.css'
export const Footer = () => {
  const [year, setYear] = useState('2023');

  useEffect(() => {
    const year = new Date().getFullYear();
    setYear(year.toString());
  }, [])

  return(
    <footer>
      <p>© {year}, NeurogenesisAI LLC. All Rights Reserved</p>
    </footer>
  )
}