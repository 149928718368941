import react from 'react';
import './Landing-Text.css'

export const LandingText = () => {
  return(
    <div className='landing-text-container'>
      <p className='landing-text'>
        Prepare for a marketing services revolution.
      </p>
    </div>
  )
}