import {Main} from './components/Main/Main'
import './App.css'
import { NavBar } from './components/NavBar/nav-bar';
import { Route, Routes } from 'react-router-dom';
import { PilotProgram } from './components/Pilot-Program/PilotProgram';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Main />}/>
      </Routes>
    </div>
  );
}

export default App;
