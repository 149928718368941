import react from 'react';
import './nav-bar.css';
import { Link } from 'react-router-dom';

export const NavBar = () => {
  return(
    <nav className='nav-bar'>
      <Link className='site-title' to="/">
        <img className='image' src='/assets/wite-logo-nobg.svg' />
        Neurogenesis AI
      </Link>
    </nav>
  )
}