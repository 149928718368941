import react from 'react';
import './Main.css'
import { LandingText } from './Landing-Text/Landing-Text';
import { Footer } from '../Footer/Footer';

export const Main = () => {
  return(
    <div className="main">
      <img
        src="/assets/stillweb.png"
        className='cover'
      />
      <LandingText />
      <Footer />
    </div>
  )
}